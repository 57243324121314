<template>
    <transition name="fade" mode="out-in">
        <b-progress variant="secondary" key="loader" v-if="loading" :value="100" :max="100" animated></b-progress>
        <div v-else key="interface">
            <b-breadcrumb :items="[{text: 'Profile', to: {name: 'Profile'}}]"></b-breadcrumb>
            <div v-if="alerts.length">
                <b-alert v-for="(item, index) in alerts" :key="index" :variant="item.variant" v-html="item.message" show></b-alert>
            </div>
            <ValidationObserver ref="form">
                <b-card title="Profile">
                    <div>
                        <label class="d-block">User Name</label>
                        <div class="h5">{{user.UserName}}</div>
                    </div>
                    <div class="mt-3">
                        <label class="d-block">Email</label>
                        <div class="h5">{{user.Email}}</div>
                    </div>
                    <ValidationProvider rules="required|confirmed:confirmation" name="Password">
                        <b-form-group label="Password" class="mt-3" slot-scope="{ valid, errors }">
                            <b-form-input type="password" v-model="form.Password" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider rules="required" name="Confirm Password" vid="confirmation">
                        <b-form-group label="Confirm Password" class="mt-3" slot-scope="{ valid, errors }">
                            <b-form-input type="password" v-model="form.PasswordConfirmation" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-card>
                <hr />
                <div class="text-center">
                    <b-button variant="primary" @click="save"><i class="fa fa-save"></i> Save</b-button>
                </div>
            </ValidationObserver>
        </div>
    </transition>
</template>
<script>
    export default {
        data() {
            return {
                loading: true,
                alerts: [],
                user: {},
                form: {
                    Password: null,
                    PasswordConfirmation: null
                },
            };
        },
        async mounted() {
            this.user = await this.$remote.request('Identity', 'Get');
            this.loading = false;
        },
        methods: {
            async save() {
                if (await this.$refs.form.validate()) {
                    let error = null;
                    try {
                        error = await this.$remote.request('Identity', 'Save', this.form);
                    } catch (ex) {
                        error = "A server error occurred";
                    }
                    if (!error) {
                        await this.$router.push({ name: 'Home' });
                        this.$bvToast.toast('Profile sucessfully saved', {
                            title: 'Saved',
                            variant: 'success',
                            toaster: 'b-toaster-bottom-center',
                            solid: true,
                            appendToast: true,
                        });
                    } else {
                        this.$bvToast.toast(error, {
                            title: 'Error',
                            variant: 'danger',
                            toaster: 'b-toaster-bottom-center',
                            solid: true,
                            appendToast: true,
                        });
                    }
                }
            }
        }
    }
</script>